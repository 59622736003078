<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card card-custom gutter-b example example-compact">
          <div
            class="
              card-header
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <div class="card-title">
              <h3 class="card-label">Shartnoma ruyhati</h3>
            </div>
          </div>
          <div class="card-body">
            <v-data-table
              :headers="headers"
              :loading="isLoading"
              loading-text="...."
              no-data-text="Malumot mavjud emas"
              :items="getContracts"
              :page.sync="page"
              :items-per-page="10"
              hide-default-footer
              class="elevation-1"
            >
              <template v-slot:[`item.index`]="{ item }">
                {{
                  getContracts
                    .map(function (x) {
                      return x.id
                    })
                    .indexOf(item.id) + 1
                }}
              </template>

              <template v-slot:[`item.action`]="{ item }">
                <action :index="item" />
              </template>
            </v-data-table>
            <!-- <div class="text-center">
                <v-pagination
                  v-model="page"
                  :length="Math.ceil(getAllCostCategories.length / 10)"
                ></v-pagination>
              </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import action from './action'
export default {
  data() {
    return {
      page: 1,
      headers: [
        {
          text: '#',
          value: 'index'
        },
        {
          text: 'Mijoz nomi',
          value: 'client_name'
        },
        {
          text: 'Boshlanish',
          value: 'start_date'
        },
        {
          text: 'Tugash',
          value: 'end_date'
        },
        {
          text: 'INN',
          value: 'client_inn'
        },
        {
          text: 'Summa',
          value: 'amount'
        },
        {
          text: 'Kategoriya',
          value: 'parent_contract_name'
        },
        {
          text: 'Shakli',
          value: 'payment_type'
        },
        {
          text: 'Turi',
          value: 'contract_type'
        },
        {
          text: '',
          value: 'action',
          sortable: false
        }
      ]
    }
  },
  components: {
    action
  },
  created() {
    this.$store.dispatch('getClientPageContract')
  },

  computed: {
    getContracts() {
      return this.$store.state.clientStore.clientContract.results
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Shartnoma ruyhati' }])
  }
}
</script>

<style scoped>
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
}
</style>
